import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { Container } from "@material-ui/core"
import CopyrightIcon from '@material-ui/icons/Copyright';

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#303841",
    display: "flex",
  },
  container: {
    display: "flex",
  },
}))

export default function BottomAppBar() {
  const classes = useStyles()

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container fixed className={classes.container}>
        <Toolbar><CopyrightIcon/> 2019 Copyright Channel.241</Toolbar>
      </Container>
    </AppBar>
  )
}
