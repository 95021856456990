import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
// import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import TranslateIcon from "@material-ui/icons/Translate"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { navigate } from "gatsby"
import ContactForm from "./form"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

const languages = require("../data/languages")

const useStyles = makeStyles(theme => ({
  appBar: {
    // backgroundColor: "#03A9F4",
    backgroundColor: "#303841",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    // display: "flex",
    // justifyContent: "space-between",
  },
  wrapper: {
    display: "flex",
  },
  rightButtons: {
    display: "flex",
  },
  button: {
    fontSize: "1.2rem",
  },
  menuItem: {
    fontSize: "1.2rem",
  },
  iconRight: {
    marginLeft: "4px",
  },
  iconLeft: {
    marginRight: "4px",
  },
}))

function ButtonAppBar(props) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedLangKey, setSelectedLangKey] = React.useState(
    props.langs.find(lang => lang.selected).langKey
  )

  const handleMenuItemClick = (event, langKey, link) => {
    setSelectedLangKey(langKey)
    setOpen(false)
    navigate(`/${link}`)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  let translateMenu = (
    <div>
      <TranslateIcon className={classes.iconLeft} />
      {languages.displayLangs[`${selectedLangKey}`]}
      <ArrowDropDownIcon className={classes.iconRight} />
    </div>
  )
  if (isWidthDown("sm", props.width)) {
    translateMenu = <TranslateIcon className={classes.iconLeft} />
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container fixed className={classes.wrapper}>
        <Toolbar className={classes.toolBar}>
          {/* <Typography variant="h6" className={classes.title}> */}
          <Button
            className={classes.button}
            size="small"
            color="inherit"
            onClick={() => navigate(props.homeLink)}
          >
            {props.siteTitle}
          </Button>
          {/* </Typography> */}
          <div className={classes.rightButtons}>
            <Button
              className={classes.button}
              variant="text"
              color="inherit"
              ref={anchorRef}
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              {translateMenu}
            </Button>
            <ContactForm url={props.url} />
          </div>
          <Popper
            className="popper-lang-menu"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ position: "absolute", zIndex: "2" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {props.langs.map((lang, index) => (
                        <MenuItem
                          className={classes.menuItem}
                          key={lang.langKey}
                          selected={lang.selected}
                          onClick={event =>
                            handleMenuItemClick(event, lang.langKey, lang.link)
                          }
                        >
                          {languages.displayLangs[`${lang.langKey}`]}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default withWidth()(ButtonAppBar)
