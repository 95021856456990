/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "./index.css"
import { Container } from "@material-ui/core"
import { IntlProvider } from "react-intl"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  main: {
    flex: "1 0 auto",
  },
  body: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    // backgroundColor: "#F0EBEB"
  },
  wrapperIe: {
    display: "flex",
    flexDirection: "column",
  },
}))

const Layout = ({ children, location, i18nMessages }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <div className={classes.wrapperIe}>
        <div className={classes.body}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            homeLink={homeLink}
            langs={langsMenu}
            url={url}
          ></Header>

          <Container fixed className={classes.main} children={children}>
            {/* {children} */}
          </Container>

          <Footer></Footer>
        </div>
      </div>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
