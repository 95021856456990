import React from "react"
import Button from "@material-ui/core/Button"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  dialog: {
    color: "#303841",
  },
}))

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({})

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(handleClose)
      .catch(error => alert(error))
  }

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen}>
        <ContactSupportIcon></ContactSupportIcon>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action={props.url}
          onSubmit={handleSubmit}
        >
          <DialogTitle id="form-dialog-title">
            <FormattedMessage id="Contact Form" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="Contact Message" />
            </DialogContentText>
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <TextField
              required
              margin="dense"
              id="summary"
              label="Summary"
              type="text"
              fullWidth
              name="summary"
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              name="description"
              multiline={true}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              name="name"
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              name="email"
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className={classes.dialog}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button className={classes.dialog} type="submit">
              <FormattedMessage id="Submit" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
