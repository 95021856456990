module.exports = {
  langs: ["en", "ja", "es", "zh", "ru", "pt", "fr", "de", "it", "nl"],
  defaultLangKey: "en",
  displayLangs: {
    "en": "English",
    "ja": "日本語",
    "es": "Espanol",
    "zh": "中国人",
    "ru": "русский",
    "pt": "idioma portugues",
    "fr": "Le français",
    "de": "Deutsch",
    "it": "italiano",
    "nl": "Nederlands",
  },
}
